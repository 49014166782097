<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5138_8899)">
      <path
        d="M9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 9.99996C1.66602 5.39746 5.39685 1.66663 9.99935 1.66663C14.6018 1.66663 18.3327 5.39746 18.3327 9.99996C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333ZM9.16602 9.16663V14.1666H10.8327V9.16663H9.16602ZM9.16602 5.83329V7.49996H10.8327V5.83329H9.16602Z"
        fill="#E80008"
      />
    </g>
    <defs>
      <clipPath id="clip0_5138_8899">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
