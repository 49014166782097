<template>
  <DrawerModal>
    <section>
      <div class="flex flex-col h-screen font-light font-ff-condensed">
        <!-- Title section -->
        <div
          :class="[hasErrors ? 'mb-[22px] md:mb-3' : 'mb-3', 'px-[30px] pt-10']"
        >
          <h2
            class="2xl:text-3xl uppercase font-normal md:text-[26px] text-2xl font-[#0F0F0F]"
          >
            {{ $t('common.signin') }}
          </h2>
          <p class="text-[#404040] text-sm xl:text-base">
            {{ $t('common.you_do_not_have_an_account') }}
            <span
              @click="() => openSignUpModal()"
              class="underline cursor-pointer font-ff-condensed-pro text-primary-500"
            >
              {{ $t('common.signup') }}
            </span>
          </p>
        </div>

        <!-- Error -->
        <div class="px-[30px]">
          <div
            v-if="hasErrors && message"
            class="px-4 py-3 mb-3 bg-red-50 rounded-[10px]"
          >
            <div class="flex items-center">
              <IconsInfoCircle class="mr-2" />
              <div class="text-sm">
                {{ message }}
              </div>
            </div>
          </div>
        </div>

        <!-- Form -->
        <div
          :class="[
            hasErrors
              ? 'xl:pt-6 md:pt-5 pt-[30px]'
              : 'pt-[30px] md:pt-10 xl:pt-[84px]',
            'px-[30px] overflow-y-scroll no-scrollbar flex-1 mb-[280px] md:mb-0',
          ]"
        >
          <form method="POST">
            <div class="mb-6">
              <!-- Email input -->
              <label
                for="email"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.signup_email') }}
              </label>
              <div class="mt-1 relative rounded-[10px] shadow-sm">
                <input
                  id="email"
                  v-model="email"
                  :placeholder="$t('common.please_enter_email')"
                  :class="hasErrors ? 'border-red-500' : 'border-gray-300'"
                  type="email"
                  name="email"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
              </div>
              <div v-if="hasErrors" class="mt-1.5 text-sm text-red-500">
                <p v-for="(errorsArray, field) in errorsObj.email" :key="field">
                  {{ errorsArray }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="password"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.password') }}
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('common.password_placeholder')"
                  :class="hasErrors ? 'border-red-500' : 'border-gray-300'"
                  name="password"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
                <div
                  class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
                >
                  <svg
                    @click="showPassword = !showPassword"
                    class="w-5 h-5 text-gray-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <template v-if="!showPassword">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </template>
                    <template v-else>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </template>
                  </svg>
                </div>
              </div>
              <div class="flex mt-1.5 justify-between">
                <div class="text-sm text-red-500">
                  <p
                    v-for="(errorsArray, field) in errorsObj.password"
                    v-show="hasErrors"
                    :key="field"
                  >
                    {{ errorsArray }}
                  </p>
                </div>
                <!-- Recover password -->
                <div class="justify-end">
                  <a
                    @click="() => openResetPasswordModal()"
                    class="text-sm text-gray-500 cursor-pointer hover:no-underline"
                  >
                    {{ $t('common.forgot_password') }}
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div
          class="absolute w-full bg-white bottom-10 left-0 right-0 px-[30px]"
        >
          <button
            :disabled="isSending"
            @click.prevent="onSubmit"
            type="submit"
            class="w-full bg-primary-500 font-ff-condensed font-medium rounded-[10px] text-white py-[10px] md:py-2 md:px-[30px] xl:px-[82px] disabled:bg-primary-300 text-base disabled:cursor-not-allowed"
          >
            {{ $t('common.signin') }}
          </button>
        </div>
      </div>
    </section>
  </DrawerModal>
</template>

<script setup>
import ResetPasswordModal from './ResetPasswordModal.vue'
import SignUpModal from './SignUpModal.vue'
import { useModal, useVfm } from 'vue-final-modal'
import { useMainStore } from '~/store'
import { useTranslation } from 'i18next-vue'
import IconsInfoCircle from '~/components/icons/InfoCircleIcon.vue'

const props = defineProps({
  accountEmail: {
    type: String,
    default: '',
  },
})

const nuxtApp = useNuxtApp()
const store = useMainStore()
const { signIn, getSession, data } = useAuth()
const { t } = useTranslation()
const vfm = useVfm()

const isSending = ref(false)
const errorsObj = ref({})
const email = ref(props.accountEmail || null)
const password = ref(null)
const showPassword = ref(false)
const message = ref(null)

const hasErrors = computed(() => {
  return Object.keys(errorsObj.value).length > 0
})

function openResetPasswordModal() {
  const { open, close } = useModal({
    component: ResetPasswordModal,
  })
  open()
}

function openSignUpModal() {
  const { open, close } = useModal({
    component: SignUpModal,
    attrs: {},
  })
  open()
}

async function onSubmit() {
  isSending.value = true
  errorsObj.value = {}

  try {
    const formData = {
      email: email.value,
      password: password.value,
      remember_me: true,
    }

    await signIn(
      formData,
      { redirect: false },
      {},
      { 'X-Tenant': store.country, 'X-Locale': store.locale }
    )

    if (window.gtag) {
      await window.gtag('event', 'login')
    }

    if (nuxtApp.$intercom) {
      nuxtApp.$intercom.update({
        user_id: data.value.data.id,
        name: data.value.data.given_name,
        email: data.value.data.email,
      })
    }

    nuxtApp.$toast.success(t('common.signin_success'))
    const redirect = nuxtApp.$cookies.get('Redirect')
    window.location = nuxtApp.$i18nPath('/')

    if (redirect && redirect !== route.fullPath) {
      vfm.closeAll()
    } else {
      vfm.closeAll()
    }
  } catch (e) {
    isSending.value = false
    console.log(e.response._data, 'error')
    if (e && !e.response._data) {
      throw e
    } else {
      errorsObj.value = e.response._data.errors
      message.value = e.response._data.message
    }
  } finally {
    isSending.value = false
  }
}
</script>
