<template>
  <VueFinalModal
    content-class="absolute sm:rounded-l-2xl  right-0 w-full md:w-[417px] h-full  p-4 space-y-2 bg-white"
    swipe-to-close="right"
    content-transition="vfm-slide-right"
    overlay-transition="vfm-fade-right"
  >
    <div
      class="absolute top-[30px] sm:block hidden bg-gray-100 rounded-l-full cursor-pointer"
      style="left: -45px"
    >
      <div
        @click="closeModal"
        class="flex item-center py-[13px] pl-[13px] pr-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_870_38049)">
            <path
              d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_870_38049">
              <rect width="24" height="24" rx="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div>
      <slot />
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, useVfm } from 'vue-final-modal'

const props = defineProps({
  componentName: {
    type: String,
    default: '',
  },
})

const emit = defineEmits()
const vfm = useVfm()

const isTransition = ref(true)
const isMobileDrawer = ref(false)

onMounted(() => {
  setTimeout(() => {
    isTransition.value = false
  }, 200)

  if (window && window.innerWidth >= 768) {
    isMobileDrawer.value = false
  } else {
    isMobileDrawer.value = true
  }
})

function closeModal() {
  vfm.closeAll()
}
</script>

<style lang="postcss">
.slide-enter-active,
.slide-leave-active {
  transition: 300ms;
}
.slide-enter-to {
  position: relative;
  left: 0;
}
.slide-leave {
  position: absolute;
}
.slide-enter {
  left: -100vw;
  position: absolute;
}
.slide-leave-to {
  right: -100vw;
}
.v--modal-overlay {
  background: rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .drawer-sidebar {
    height: calc(100vh - 110px);
    @apply left-0 rounded-t-2xl bottom-0;
  }
}
</style>
